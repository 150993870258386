import type { MenuItem, MenuItemLinkMetaData } from '../types';

// TODO: https://wix.atlassian.net/browse/MA-4435
export const normalizeMenuItemsLinkMetadata = (
  menuItems: MenuItem[],
): MenuItem[] => {
  return menuItems.map((item) => {
    if (item.items?.length) {
      return {
        ...item,
        items: normalizeMenuItemsLinkMetadata(item.items),
      };
    }

    const { linkMetaData } = item;

    if (!linkMetaData) {
      return item;
    }

    const allowedProperties = [
      'type',
      'target',
      'rel',
      'linkPopupId',
      'anchorDataId',
      'anchorCompId',
      'docInfo',
    ];

    const normalizedLinkMetaData = Object.fromEntries(
      Object.entries(linkMetaData).filter(([key, value]) => {
        return allowedProperties.includes(key) && value !== undefined;
      }),
    ) as MenuItemLinkMetaData;

    return {
      ...item,
      linkMetaData: normalizedLinkMetaData,
    };
  });
};
