import { getRoles } from '@wix/ambassador-members-v1-role/http';
import { withResolvedError } from '@wix/members-area-commons-ts';
import { type IHttpClient } from '@wix/yoshi-flow-editor';

import type { ErrorHandler, RolesService as IRolesService } from '../types';

export class RolesService implements IRolesService {
  constructor(
    private httpClient: IHttpClient,
    private errorHandler: ErrorHandler,
  ) {}

  async getMemberRoles(memberId: string) {
    const requestFn = () => this.httpClient.request(getRoles({ memberId }));

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data.roles?.map((role) => role.roleKey?.toLocaleLowerCase()!) || [];
  }
}
