import type { ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';

enum ErrorSeverity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

const INTERACTIONS_SAMPLE_RATIO = 0.1;

let panorama: ViewerScriptFlowAPI['panoramaClient'];
let interactionsMonitoringEnabled: boolean;

export const initializeMonitoring = (
  panoramaClient: ViewerScriptFlowAPI['panoramaClient'],
) => {
  const sessionSampleRatio = Math.random();

  panorama = panoramaClient;
  interactionsMonitoringEnabled =
    INTERACTIONS_SAMPLE_RATIO >= sessionSampleRatio;
};

function interactionStarted(interactionName: string) {
  try {
    interactionsMonitoringEnabled &&
      panorama?.transaction(interactionName).start();
  } catch (e) {
    const err = 'Failed to start fedops interaction, reason: ' + e;
    panorama?.errorMonitor().reportError(new Error(err));
  }
}

function interactionEnded(interactionName: string) {
  try {
    interactionsMonitoringEnabled &&
      panorama?.transaction(interactionName).finish();
  } catch (e) {
    const err = 'Failed to end fedops interaction, reason: ' + e;
    panorama?.errorMonitor().reportError(new Error(err));
  }
}

export const toMonitored = (interactionName: string, fn: () => any) => () => {
  try {
    interactionStarted(interactionName);
    const response = fn();
    interactionEnded(interactionName);
    return response;
  } catch (e) {
    console.error(e);

    panorama?.errorMonitor().reportError(e as Error);
    throw e;
  }
};

export function log(message: string, options = {}) {
  panorama?.errorMonitor().reportError(new Error(message), {
    severity: ErrorSeverity.Info,
    ...options,
  });
}

export function logError(message: string, options = {}) {
  panorama?.errorMonitor().reportError(new Error(message), {
    severity: ErrorSeverity.Error,
    ...options,
  });
}
