import { withResolvedError } from '@wix/members-area-commons-ts';
import type {
  IHttpClient,
  IPlatformServices,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';

import { USER_NAME_PATTERN } from '../../constants';
import { RolesService } from '../../services/roles';
import type {
  GetMyMemberResponse,
  Member,
  MemberData,
  RolesMap,
  WixCodeApi,
} from '../../types';
import { logError } from '../../utils/monitoring';
import type { MemberActivityCounters } from './activity-counters-service';
import { getMemberActivityCounters } from './activity-counters-service';
import { getMemoryStorage } from './memory-storage';

const CURRENT_USER_SLUG_STORAGE_KEY = 'current-user-slug';
const CURRENT_USER_ID_STORAGE_KEY = 'current-user-id';

type CreateUserServiceOptions = {
  wixCodeApi: WixCodeApi;
  biService: IPlatformServices['bi'];
  flowAPI: ViewerScriptFlowAPI;
};

export function createUserService({
  wixCodeApi,
  biService,
  flowAPI,
}: CreateUserServiceOptions) {
  const rolesService = new RolesService(
    flowAPI.httpClient,
    flowAPI.errorHandler,
  );

  let viewedUser: Member, currentUser: Member, userRoles: RolesMap;

  function fetchMenuCounters(
    user: Member,
  ): Promise<{ apps: MemberActivityCounters }> {
    if (!user || user.loggedIn === false) {
      return Promise.resolve({ apps: [] });
    }
    return new Promise<{ apps: MemberActivityCounters }>((resolve, reject) => {
      const userId = user && user.id;
      if (userId) {
        getMemberActivityCounters({
          memberId: userId,
          loggedIn: !!user.loggedIn,
          biService,
          flowAPI,
          wixCodeApi,
        }).then((response) => resolve({ apps: response }));
      } else {
        reject(new Error('No user to get menu counters by'));
      }
    });
  }

  async function fetchRoles(
    viewedUserId: string,
    loggedInUserId: string,
  ): Promise<RolesMap> {
    const [viewedMemberRoles, loggedInMemberRoles] = await Promise.all([
      viewedUserId &&
        rolesService.getMemberRoles(viewedUserId).catch(() => undefined),

      loggedInUserId &&
        rolesService.getMemberRoles(loggedInUserId).catch(() => undefined),
    ]);

    return {
      ...(viewedMemberRoles && {
        [viewedUserId]: viewedMemberRoles,
      }),
      ...(loggedInMemberRoles && {
        [loggedInUserId]: loggedInMemberRoles,
      }),
    };
  }

  function replaceUserPatternWithSlug(url: string, user: Member) {
    return url
      .replace(USER_NAME_PATTERN, user.slug)
      .replace(encodeURI(USER_NAME_PATTERN), user.slug);
  }

  function getViewedUser() {
    return viewedUser ?? {};
  }

  function getCurrentUser() {
    return currentUser ?? {};
  }

  function getRoles() {
    return userRoles ?? {};
  }

  function setRoles(roles: RolesMap) {
    userRoles = roles;
  }

  function setViewedUser(userData: Member) {
    if (userData) {
      viewedUser = userData;
    }
  }

  async function setCurrentUser(userData: MemberData, httpClient: IHttpClient) {
    const slug = await getCurrentUserSlug(userData, httpClient);

    currentUser = {
      id: userData.id,
      loggedIn: userData.loggedIn,
      slug: slug as string,
    };
  }

  async function fetchCurrentUserSlug(httpClient: IHttpClient) {
    const requestFn = () =>
      httpClient.get<GetMyMemberResponse>('/_api/members/v1/members/my');

    const { data } = await withResolvedError(requestFn, flowAPI.errorHandler, {
      errorCodesMap: {},
    });

    return data.member.profile.slug;
  }

  function getCurrentUserSlug(userData: MemberData, httpClient: IHttpClient) {
    const memoryStorage = getMemoryStorage();
    const storageSlug = memoryStorage.getItem(CURRENT_USER_SLUG_STORAGE_KEY);
    const currentUserId = memoryStorage.getItem(CURRENT_USER_ID_STORAGE_KEY);

    if (storageSlug && currentUserId === userData.id) {
      return storageSlug;
    }

    if (!currentUserId || currentUserId !== userData.id) {
      memoryStorage.setItem(CURRENT_USER_ID_STORAGE_KEY, userData.id);
    }

    if (!userData.loggedIn || wixCodeApi.window.viewMode !== 'Site') {
      return userData.id;
    }

    // Calling manually instead of userData.loggedIn.getSlug to not depend on their implementation
    // This was applied as a hotfix because of broken userData.loggedIn.getSlug implementation
    return fetchCurrentUserSlug(httpClient)
      .then((slug) => {
        memoryStorage.setItem(CURRENT_USER_SLUG_STORAGE_KEY, slug);
        return slug;
      })
      .catch(() => {
        logError('Error while fetching current user slug', {
          userDataId: userData.id,
        });

        return userData.id;
      });
  }

  return {
    getCurrentUser,
    setCurrentUser,
    getViewedUser,
    setViewedUser,
    fetchRoles,
    getRoles,
    setRoles,
    fetchMenuCounters,
    replaceUserPatternWithSlug,
  };
}

export type UserService = ReturnType<typeof createUserService>;
