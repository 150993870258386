import { getMyMember } from '@wix/ambassador-members-v1-member/http';
import { Set } from '@wix/ambassador-members-v1-member/types';
import { withResolvedError } from '@wix/members-area-commons-ts';
import { type IHttpClient } from '@wix/yoshi-flow-editor';

import type {
  CurrentUserService as ICurrentUserService,
  ErrorHandler,
} from '../types';

export class CurrentUserService implements ICurrentUserService {
  constructor(
    private httpClient: IHttpClient,
    private errorHandler: ErrorHandler,
  ) {}

  async getCurrentUser() {
    const requestFn = () =>
      this.httpClient.request(getMyMember({ fieldSet: Set.FULL }));

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data.member ?? null;
  }
}
